import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Cards() {
  return (
    <>
      <div className="section section-cards">
        <Container>
          <Row>
            <Col className="text-center ml-auto mr-auto" md="8">
              <div className="section-description">
                <h2 className="title">Beautiful Cards</h2>
                <h6 className="category">One Card for Every Problem</h6>
                <h5 className="description">
NFT, DeFi will be the new blood to infuse the real estate market to create a win win of home owners and investors!
                </h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="images-container">
                <div className="image-container1 mr-1">
                  <img
                    alt="..."
                    src={
                      require("assets/img/presentation-page/card3.jpg").default
                    }
                  ></img>
                </div>
                <div className="image-container2 mr-1">
                  <img
                    alt="..."
                    src={
                      require("assets/img/presentation-page/card6.jpg").default
                    }
                  ></img>
                </div>
                <div className="image-container3 mr-1">
                  <img
                    alt="..."
                    src={
                      require("assets/img/presentation-page/card7.jpg").default
                    }
                  ></img>
                </div>
                <div className="image-container4 mr-1">
                  <img
                    alt="..."
                    src={
                      require("assets/img/presentation-page/card5.jpg").default
                    }
                  ></img>
                </div>
                <div className="image-container5">
                  <img
                    alt="..."
                    src={
                      require("assets/img/presentation-page/card4.jpg").default
                    }
                  ></img>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Cards;
