import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

// core components

function BlogPostHeader() {
  let pageHeader = React.createRef();
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/bg24.jpg").default + ")",
          }}
          ref={pageHeader}
        ></div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">29 耶稣回答说：第一要紧的，就是说：
              以色列啊，你要听，主我们神是独一的主。 
              </h2>
             </Col>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">
                
              30 你要尽心、尽性、尽意、尽力爱主你的　神。
                
              </h2>
     
            </Col>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">
                31 其次就是说：要爱人如己。 再没有比这两条诫命更大的了。马可福音 12:29-31 和合本
              </h2>
     
            </Col>
          </Row>
          <Row>

            <Col className="ml-auto mr-auto text-center" md="8">
              <h4>29 ἀπεκρίθη ὁ Ἰησοῦς ὅτι πρώτη ἐστίν ἄκουε Ἰσραήλ κύριος ὁ θεὸς ἡμῶν κύριος εἷς ἐστιν
              
              </h4>
            </Col>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h4>
                30 καὶ ἀγαπήσεις κύριον τὸν θεόν σου ἐξ ὅλης τῆς καρδίας σου καὶ ἐξ ὅλης τῆς ψυχῆς σου καὶ ἐξ ὅλης τῆς διανοίας σου καὶ ἐξ ὅλης τῆς ἰσχύος σου
                
              </h4>
            </Col>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h4>
                31 δευτέρα αὕτη ἀγαπήσεις τὸν πλησίον σου ὡς σεαυτόν μείζων τούτων ἄλλη ἐντολὴ οὐκ ἔστιν
              </h4>
            </Col>

          </Row>
        </div>
      </div>
    </>
  );
}

export default BlogPostHeader;
