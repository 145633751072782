import React from "react";

// reactstrap components
import { Button, Row, Col } from "reactstrap";

// core components

function BlogPostsHeader() {
  let pageHeader = React.createRef();
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/bg44.jpg").default + ")",
          }}
          ref={pageHeader}
        ></div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">
              16要常常喜乐，17不住地祷告，18凡事谢恩；因为这是神在基督耶稣里向你们所定的旨意。19不要消灭圣灵的感动；20不要藐视先知的讲论。21但要凡事察验；善美的要持守，22各样的恶事要禁戒不做。
              17 ἀδιαλείπτως προσεύχεσθε18 ἐν παντὶ εὐχαριστεῖτε τοῦτο γὰρ θέλημα θεοῦ ἐν Χριστῷ Ἰησοῦ εἰς ὑμᾶς19 τὸ πνεῦμα μὴ σβέννυτε20 προφητείας μὴ ἐξουθενεῖτε21 πάντα δὲ δοκιμάζετε τὸ καλὸν κατέχετε22 ἀπὸ παντὸς εἴδους πονηροῦ ἀπέχεσθε
              </h2>
              <Button className="btn-round btn-icon mr-1" color="info">
                <i className="fab fa-twitter"></i>
              </Button>
              <Button className="btn-round btn-icon" color="info">
                <i className="fab fa-instagram"></i>
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default BlogPostsHeader;
