import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  CardBody,
  Table,
  Container,
  Row,
  Col,

} from "reactstrap";

// core components
import FixedTransparentNavbar from "components/Navbars/FixedTransparentNavbar.js";
import BlogPostHeader from "components/Headers/BlogPostHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";

function BlogPost() {
  React.useEffect(() => {
    document.body.classList.add("blog-post");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-post");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <FixedTransparentNavbar />
      <div className="wrapper">
        <BlogPostHeader />
        <div className="section">
          <Container>
            <Row>
              <Col md="12">
                <div className="button-container">
                  <Button
                    className="btn-round mr-1"
                    color="info"
                    size="lg"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="now-ui-icons text_align-left"></i> Read
                    Article
                  </Button>
                  <Button
                    className="btn-icon btn-round mr-1"
                    color="twitter"
                    size="lg"
                  >
                    <i className="fab fa-twitter"></i>
                  </Button>
                  <Button
                    className="btn-icon btn-round mr-1"
                    color="facebook"
                    size="lg"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </Button>
                  <Button
                    className="btn-icon btn-round"
                    color="google"
                    size="lg"
                  >
                    <i className="fab fa-google"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="section">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h3 className="title">
                    50 years CCIC anniversary and winter retreat...
                    CCIC50周年庆典与感恩，冬令会2021
                  </h3>
                  <p>
                    很高兴看到黎焕森弟兄，黄长老和玛莎姐妹身体健康，灵里活泼
                    
                    . <br></br>
                    <br></br>
                    親愛的弟兄姐妹們，感恩節快樂！每年的感恩節冬令會是會堂最大最隆重的屬靈聚會，今年的冬令會尤其不容易，但是藉著弟兄姐妹的禱告，還有一大批同工的擺上，冬令會將於今天下午兩點正式登場！我們感謝神的同時，也歡迎、鼓勵您準時參加（無論報名與否都可以參加），並一起為這次聚會禱告！
                          
                          本次冬令會概覽
                          （1）今年冬令會將於本週五下午2：00開始（1：30開始報到），在教會舉行；
                          （2）主題是《跨代信仰傳承》，主題講員是林永健牧師，專題講員是林牧師、林師母、張志吉牧師和會堂傳道人Martha。
                          （3）有五堂主題信息；週五和週六下午有兒童教養、禱告、退休及特別分享等四個專題；
                          （4）週五晚上7：30有教會五十週年感恩聚會，週六晚餐6：30有Martha送行會；
                          （5）冬令會期間備有顧嬰、兒童和青少年節目。
                          所有聚會詳情請在下面點擊報名鏈接處查看。
                          鼓勵弟兄姐妹繼續報名，報名請點擊：2021年 聖荷西/中聖荷西 基督徒會堂 聯合冬令會 主題：跨代信仰傳承，講員：林永健牧師，（11/26-28，週五-主日）
                          
                          參加實體冬令會的弟兄姐妹請留意：
                          為了有好的聽道和小組討論效果，教會鼓勵弟兄姐妹參加實體聚會。聚會時間是：週五和週六下午2：00-9：30PM（有晚餐），主日早上9：15AM-12：15PM。
                          （1）有訂餐的弟兄姐妹，來聚會 check in時請帶好現金或支票；
                          （2）小組討論安排請留意教會門口公告及信息後的報告事項；
                          （2）請自帶水杯；
                          （4）請戴好口罩；
                          
                          參加線上冬令會的弟兄姐妹請留意：
                          （1）鼓勵您全程參加，提前預留時間；
                          （2）在線觀看直播所有主題信息，請安冬令會聚會時間打開教會Youtube链接， https://www.youtube.com/c/CCICSanJose
                          （3) 在線參加週五下午5：00專題《從詩篇學習禱告》及週六下午5：00專題《落日餘暉，仍綻光芒》，請用ZOOM ID：793-970-5467（1490）
                                    其它兩個專題（也是下午5：00同時進行），請點擊：
                                   https://www.youtube.com/c/CCICSanJose
                          （4）參加線上週五和週六下午（3：45-4：45）小組討論：
                                  錫安團契弟兄姐妹請用ZOOM ID:419-317-0650（1490）
                                  良友和沒參加團契的弟兄姐妹請用ZOOM ID：449-745-1683（1490）
                               （註：參加現場聚會的弟兄姐妹的分組，會現場報告）
                  </p>
                  <p className="blockquote blockquote-info">
                  11/26 週五 ～ 11/28 主日

讲员： 吕绍昌牧师

实体聚会地点： CCIC CUPERTINO

网路连结：https://www.youtube.com/c/CCICCupertino

週五晚间和週日上午的儿童和青少年聚会照常；但週六上午没有安排特別的儿童和青少年活动。您如果在週六上午来教会、同时有照顾孩子的需要，请通知我们儿童事工牧者王洁姊妹，以便统计人数、做安排。

11/26 週五晚
7:30 pm – 9:30pm
查验生命呼召 （马可 8：27-38）
11/27 週六上午
9:30am – 10:45am
荣耀基督肢体 （以弗所 3:14-21）

11:00am – 12:30pm
满有基督身量 （以弗所4:7-16）
11/28 主日上午
9:30am – 10:45am
思想效法基督 （以弗所4:17-24）

11:00am – 12:30pm
回归基督之爱 （启示录2:1-7） <br></br>
                    <br></br>
                    <small>CCIC-Cupertino, CA, USA</small>
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          <Col className="section section-blog ml-auto mr-auto" md="10">
            <Container>
            <div className="section-story-overview">
                <Row>
                  <Col md="6">
                    <div
                      className="image-container image-left"
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/bg35.jpg").default + ")",
                      }}
                    >
                      <p className="blockquote blockquote-info">
                  Jesus
                        


                        <small>-‪1-24-2022   

                        今天是23号
“耶和华是我的牧者， 我必不致缺乏。 他使我躺卧在青草地上， 领我在可安歇的水边。 他使我的灵魂苏醒， 为自己的名引导我走义路。 我虽然行过死荫的幽谷， 也不怕遭害， 因为你与我同在； 你的杖，你的竿，都安慰我。 在我敌人面前，你为我摆设筵席； 你用油膏了我的头，使我的福杯满溢。 我一生一世必有恩惠慈爱随着我； 我且要住在耶和华的殿中，直到永远。
https://bible.com/bible/48/psa.23.1-6.CUNPSS-神”
‭‭诗篇‬ ‭23:1-6‬ ‭CUNPSS-神‬‬。</small>
                      </p>
                      <p>“你以恩典为年岁的冠冕； 你的路径都滴下脂油，”
‭‭诗篇‬ ‭65:11‬ ‭CUNPSS-神‬‬
                        <p>感谢赞美主给弟兄姐妹的丰富预备，我们一起领受被建造，特别感恩神听了大家的祷告让黄长老能得医治回到我们中间，他以一个为父的心把时间精力巴心巴肝的交给大家，真的是把命都摆上了为了造就弟兄姐妹们可以对神的话有胃口，深深地扎根，守住真道。并把自己摆在我们面前！愿主怜悯，多给我们一些时间[合十]
                        </p>
                          
                      </p>
                    </div>
                    <div
                      className="image-container image-left-bottom"
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/bg29.jpg").default + ")",
                      }}
                    ></div>
                  </Col>
                  <Col md="5">
                    <div
                      className="image-container image-right"
                      style={{
                        backgroundImage:
                          "url(" +
                          require("assets/img/project16.jpg").default +
                          ")",
                      }}
                    ></div>
                    <h3>
                      高举圣经，高举耶稣基督，荣耀神
                    </h3>
                    <p>
                      从查经班到教会.
                    </p>
                    <p>
                    ‪提摩太后书‬3:15-17 和合本
15 并且知道你是从小明白圣经，这圣经能使你因信基督耶稣，有得救的智慧。 16 圣经都是　神所默示的（或作“凡　神所默示的圣经”），于教训、督责、使人归正、教导人学义都是有益的， 17 叫属　神的人得以完全，预备行各样的善事。
                    </p>

                  </Col>
                </Row>
              </div>
              <div className="section-story-overview">
                <Row>
                  <Col md="6">
                    <div
                      className="image-container image-left"
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/bg35.jpg").default + ")",
                      }}
                    >
                      <p className="blockquote blockquote-info">
                  Jesus
                        


                        <small>-‪1-24-2022   

诗篇24:3-6 
3 谁能登耶和华的山？谁能站在他的圣所？
4 就是手洁心清，不向虚妄，起誓不怀诡诈的人。
5 他必蒙耶和华赐福，又蒙救他的 神使他成义。
6 这是寻求耶和华的族类，是寻求你面的雅各。</small>
                      </p>
                    </div>
                    <div
                      className="image-container image-left-bottom"
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/bg29.jpg").default + ")",
                      }}
                    ></div>
                  </Col>
                  <Col md="5">
                    <div
                      className="image-container image-right"
                      style={{
                        backgroundImage:
                          "url(" +
                          require("assets/img/project16.jpg").default +
                          ")",
                      }}
                    ></div>
                    <h3>
                      守道
                    </h3>
                    <p>
                    prayer
                    </p>
                    <p>
                    
                    感谢赞美主给弟兄姐妹的丰富预备，我们一起领受被建造，特别感恩神听了大家的祷告让黄长老能得医治回到我们中间，他以一个为父的心把时间精力巴心巴肝的交给大家，真的是把命都摆上了为了造就弟兄姐妹们可以对神的话有胃口，深深地扎根，守住真道。并把自己摆在我们面前！愿主怜悯，多给我们一些时间[合十]
                    </p>

                  </Col>
                </Row>
              </div>
            </Container>
          </Col>
          <div className="section">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h3 className="title">Rest of the Story:</h3>

                  <p>
                    I love this new Ferg album! The Life of Pablo is now
                    available for purchase I have a dream. Thank you to
                    everybody who made The Life of Pablo the number 1 album in
                    the world! I'm so proud of the nr #1 song in the country.
                    Panda! Good music 2016!

                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h3 className="title">Rest of the Story:</h3>

                  <p>
========================

                  </p>
                </Col>
              </Row>

            </Container>
          </div>
          <div className="section">
            <Container>
              <Col md="12">
                <h2 className="title text-center">Gospels -Jesus's Birth</h2>
                <br></br>
                <div className="blogs-1" id="blogs-1">
                  <Row>
                    <Col className="ml-auto mr-auto" md="10">
                      <Card className="card-plain card-blog">
                        <Row>
                          <Col md="5">
                            <div className="card-image">
                              <img
                                alt="..."
                                className="img img-raised rounded"
                                src={
                                  require("assets/img/mise/card-blog4.jpg")
                                    .default
                                }
                              ></img>
                            </div>
                          </Col>
                          <Col md="7">
                            <h6 className="category text-info">Emannuel</h6>
                            <CardTitle tag="h3">
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                               Jesus in Chinese Union
                              </a>
                            </CardTitle>
                            <p className="card-description">
                            
                            <p>

                    ‪约翰福音‬1:1-5 和合本
                    1 太初有道，道与　神同在，道就是　神。 2 这道太初与　神同在。 3 万物是藉着他造的；凡被造的，没有一样不是藉着他造的。 4 生命在他里头，这生命就是人的光。 5 光照在黑暗里，黑暗却不接受光。
                    
                    路加福音1:46-55 和合本
                    46 马利亚说：“我心尊主为大；
                    47 我灵以　神我的救主为乐；
                    48 因为他顾念他使女的卑微；从今以后，万代要称我有福。
                    49 那有权能的，为我成就了大事；他的名为圣。
                    50 他怜悯敬畏他的人，直到世世代代。
                    51 他用膀臂施展大能；那狂傲的人正心里妄想就被他赶散了。
                    52 他叫有权柄的失位，叫卑贱的升高；
                    53 叫饥饿的得饱美食，叫富足的空手回去。
                    54 他扶助了他的仆人以色列，
                              55 为要记念亚伯拉罕和他的后裔，施怜悯直到永远，正如从前对我们列祖所说的话。”
                              </p>
                            <p>
                    ‪马太福音‬1:23 和合本
23 说：
“必有童女怀孕生子；人要称他的名为以马内利。”
（“以马内利”翻出来就是“　神与我们同在”。）
                    路加福音2:14 和合本
                    14 “在至高之处荣耀归与　神！在地上平安归与他所喜悦的人（有古卷作“喜悦归与人”）。”
                    </p>
                            <p>
                    ‪路加福音‬1:68-79 和合本
68 “主以色列的　神是应当称颂的！因他眷顾他的百姓，为他们施行救赎，
69 在他仆人大卫家中，为我们兴起了拯救的角，
70 正如主藉着从创世以来圣先知的口所说的话，
71 拯救我们脱离仇敌和一切恨我们之人的手，
72 向我们列祖施怜悯，记念他的圣约，
73 就是他对我们祖宗亚伯拉罕所起的誓，
74 叫我们既从仇敌手中被救出来，
75 就可以终身在他面前，坦然无惧地用圣洁、公义侍奉他。
76 孩子啊！你要称为至高者的先知；因为你要行在主的前面，预备他的道路，
77 叫他的百姓因罪得赦，就知道救恩。
78 因我们　神怜悯的心肠，叫清晨的日光从高天临到我们，
                    79 要照亮坐在黑暗中死荫里的人，把我们的脚引到平安的路上。”
                    </p>
                            <p></p>
                    ‪路加福音‬2:29-32 和合本
29 “主啊！如今可以照你的话，释放仆人安然去世；
                    30 因为我的眼睛已经看见你的救恩，
                    31 就是你在万民面前所预备的，
                    32 是照亮外邦人的光，又是你民以色列的荣耀。”
                    </p>
                            <p>
                    ‪路加福音‬2:40 和合本
                    40 孩子渐渐长大，强健起来，充满智慧，又有　神的恩在他身上。
                    </p>
                            <p>
                    ‪路加福音‬2:49,52 和合本
49 耶稣说：“为什么找我呢？岂不知我应当以我父的事为念吗（或作“岂不知我应当在我父的家里吗”）？”……52 耶稣的智慧和身量（“身量”或作“年纪”），并　神和人喜爱他的心，都一齐增长。
</p>
                            <p>
‪约翰福音‬1:6-18 和合本
6 有一个人，是从　神那里差来的，名叫约翰。 7 这人来，为要作见证，就是为光作见证，叫众人因他可以信。 8 他不是那光，乃是要为光作见证。 9 那光是真光，照亮一切生在世上的人。 10 他在世界，世界也是藉着他造的，世界却不认识他。 11 他到自己的地方来，自己的人倒不接待他。 12 凡接待他的，就是信他名的人，他就赐他们权柄作　神的儿女。 13 这等人不是从血气生的，不是从情欲生的，也不是从人意生的，乃是从　神生的。
                    14 道成了肉身，住在我们中间，充充满满地有恩典有真理。我们也见过他的荣光，正是父独生子的荣光。 15 约翰为他作见证，喊着说：“这就是我曾说：‘那在我以后来的，反成了在我以前的，因他本来在我以前。’” 16 从他丰满的恩典里，我们都领受了，而且恩上加恩。 17 律法本是藉着摩西传的；恩典和真理都是由耶稣基督来的。 18 从来没有人看见　神，只有在父怀里的独生子将他表明出来。
                    </p>
                            <p>

                    施洗约翰传道
（可1·1－8；路3·1－18；约1·19－28）
1那时，有施洗的约翰出来，在犹太的旷野传道，说： 2“天国近了，你们应当悔改！” 3这人就是先知以赛亚所说的。他说：“在旷野有人声喊着说：
‘预备主的道，
修直他的路！’”
4这约翰身穿骆驼毛的衣服，腰束皮带，吃的是蝗虫、野蜜。 5那时，耶路撒冷和犹太全地，并约旦河一带地方的人，都出去到约翰那里， 6承认他们的罪，在约旦河里受他的洗。 7约翰看见许多法利赛人和撒都该人也来受洗，就对他们说：“毒蛇的种类！谁指示你们逃避将来的忿怒呢？ 8你们要结出果子来，与悔改的心相称。 9不要自己心里说：‘有亚伯拉罕为我们的祖宗。’我告诉你们，　神能从这些石头中给亚伯拉罕兴起子孙来。 10现在斧子已经放在树根上，凡不结好果子的树就砍下来，丢在火里。 11我是用水给你们施洗，叫你们悔改。但那在我以后来的，能力比我更大，我就是给他提鞋也不配。他要用圣灵与火给你们施洗。 12他手里拿着簸箕，要扬净他的场，把麦子收在仓里，把糠用不灭的火烧尽了。”
耶稣受洗
（可1·9－11；路3·21－22）
                    13当下，耶稣从加利利来到约旦河，见了约翰，要受他的洗。 14约翰想要拦住他，说：“我当受你的洗，你反倒上我这里来吗？” 15耶稣回答说：“你暂且许我，因为我们理当这样尽诸般的义（或作“礼”）。”于是约翰许了他。 16耶稣受了洗，随即从水里上来。天忽然为他开了，他就看见　神的灵仿佛鸽子降下，落在他身上。 17从天上有声音说：“这是我的爱子，我所喜悦的。”
                    </p>
                            <p>                
                    施洗约翰传道
（太3·1－12；路3·1－18；约1·19－28）
1　神的儿子，耶稣基督福音的起头。 2正如先知以赛亚（有古卷无“以赛亚”三字）书上记着说：
“看哪，我要差遣我的使者在你前面，
预备道路。
3在旷野有人声喊着说：
‘预备主的道，
修直他的路。’”
4 照这话，约翰来了，在旷野施洗，传悔改的洗礼，使罪得赦。 5犹太全地和耶路撒冷的人都出去到约翰那里，承认他们的罪，在约旦河里受他的洗。 6约翰穿骆驼毛的衣服，腰束皮带，吃的是蝗虫、野蜜。 7他传道说：“有一位在我以后来的，能力比我更大，我就是弯腰给他解鞋带也是不配的。 8我是用水给你们施洗，他却要用圣灵给你们施洗。”
耶稣受洗
（太3·13－17；路3·21－22）
9那时，耶稣从加利利的拿撒勒来，在约旦河里受了约翰的洗。10他从水里一上来，就看见天裂开了，圣灵仿佛鸽子，降在他身上。 11又有声音从天上来，说：“你是我的爱子，我喜悦你。”
</p>
                            <p>
2亚那和该亚法作大祭司。那时，撒迦利亚的儿子约翰在旷野里，　神的话临到他。 3他就来到约旦河一带地方，宣讲悔改的洗礼，使罪得赦。 4正如先知以赛亚书上所记的话，说：
“在旷野有人声喊着说：
‘预备主的道，
修直他的路！
5一切山洼都要填满；
大小山冈都要削平！
弯弯曲曲的地方要改为正直；
高高低低的道路要改为平坦！
6凡有血气的，都要见　神的救恩！’”
7 约翰对那出来要受他洗的众人说：“毒蛇的种类！谁指示你们逃避将来的忿怒呢？ 8你们要结出果子来，与悔改的心相称。不要自己心里说：‘有亚伯拉罕为我们的祖宗。’我告诉你们，　神能从这些石头中，给亚伯拉罕兴起子孙来。 9现在斧子已经放在树根上，凡不结好果子的树就砍下来，丢在火里。” 10众人问他说：“这样，我们当做什么呢？” 11约翰回答说：“有两件衣裳的，就分给那没有的；有食物的，也当这样行。” 12又有税吏来要受洗，问他说：“夫子，我们当做什么呢？” 13约翰说：“除了例定的数目，不要多取。” 14又有兵丁问他说：“我们当做什么呢？”约翰说：“不要以强暴待人，也不要讹诈人，自己有钱粮就当知足。”
15百姓指望基督来的时候，人都心里猜疑，或者约翰是基督。 16约翰说：“我是用水给你们施洗，但有一位能力比我更大的要来，我就是给他解鞋带也不配。他要用圣灵与火给你们施洗。 17他手里拿着簸箕，要扬净他的场，把麦子收在仓里，把糠用不灭的火烧尽了。” 18约翰又用许多别的话劝百姓，向他们传福音。 19只是分封的王希律，因他兄弟之妻希罗底的缘故，并因他所行的一切恶事，受了约翰的责备； 20又另外添了一件，就是把约翰收在监里。
</p>
                            <p>
                              耶稣受洗
（太3·13－17；可1·9－11）
                    21众百姓都受了洗，耶稣也受了洗。正祷告的时候，天就开了， 22圣灵降临在他身上，形状仿佛鸽子；又有声音从天上来，说：“你是我的爱子，我喜悦你。”
                    </p>
                            <p>
                    施洗约翰的见证
（太3·1－12；可1·1－8；路3·1－18）
19 约翰所作的见证记在下面：犹太人从耶路撒冷差祭司和利未人到约翰那里，问他说：“你是谁？” 20他就明说，并不隐瞒，明说：“我不是基督。” 21他们又问他说：“这样，你是谁呢？是以利亚吗？”他说：“我不是。”“是那先知吗？”他回答说：“不是。” 22于是他们说：“你到底是谁，叫我们好回复差我们来的人。你自己说，你是谁？” 23他说：“我就是那在旷野有人声喊着说：‘修直主的道路’，正如先知以赛亚所说的。” 24那些人是法利赛人差来的（或作“那差来的是法利赛人”）； 25他们就问他说：“你既不是基督，不是以利亚，也不是那先知，为什么施洗呢？” 26约翰回答说：“我是用水施洗，但有一位站在你们中间，是你们不认识的， 27就是那在我以后来的，我给他解鞋带也不配。” 28这是在约旦河外伯大尼（有古卷作“伯大巴喇”），约翰施洗的地方作的见证。
　神的羔羊
                    29次日，约翰看见耶稣来到他那里，就说：“看哪，　神的羔羊，除去（或作“背负”）世人罪孽的！ 30这就是我曾说：‘有一位在我以后来、反成了在我以前的，因他本来在我以前。’ 31我先前不认识他，如今我来用水施洗，为要叫他显明给以色列人。” 32约翰又作见证说：“我曾看见圣灵，仿佛鸽子从天降下，住在他的身上。 33我先前不认识他，只是那差我来用水施洗的、对我说：‘你看见圣灵降下来，住在谁的身上，谁就是用圣灵施洗的。’ 34我看见了，就证明这是　神的儿子。”
               

                    
                  </p> 
                            <p className="author">
                              by{" "}
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <b>Sarah Perez</b>
                              </a>
                              , 2 days ago
                            </p>
                          </Col>
                        </Row>
                      </Card>
                      <Card className="card-plain card-blog">
                        <Row>
                          <Col md="7">
                            <h6 className="category text-danger">
                              <i className="now-ui-icons now-ui-icons media-2_sound-wave"></i>{" "}
                              In God we trust!
                            </h6>
                            <CardTitle tag="h3">
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                 Jesus in NASB 
                              </a>
                            </CardTitle>
                            <p className="card-description">
                   
                            <p>                    The Deity of Jesus Christ
                                1In the beginning was the Word, and the Word was with God, and the Word was God. 2He was in the beginning with God. 3All things came into being through Him, and apart from Him not even one thing came into being that has come into being. 4In Him was life, and the life was the Light of mankind. 5And the Light shines in the darkness, and the darkness did not grasp it.
                             </p>
                   <p> 46And Mary said:“My soul exalts the Lord,
                    47And my spirit has rejoiced in God my Savior.
                    48For He has had regard for the humble state of His bond-servant;For behold, from now on all generations will call me blessed.
                    49For the Mighty One has done great things for me;And holy is His name.
                    50And His mercy is to generation after generation Toward those who fear Him.
                    51He has done mighty deeds with His arm; He has scattered those who were proud in the thoughts of their hearts.
                    52He has brought down rulers from their thrones, And has exalted those who were humble.
                    53He has filled the hungry with good things, And sent the rich away empty-handed.
                    54He has given help to His servant Israel, In remembrance of His mercy,
                    55Just as He spoke to our fathers, To Abraham and his descendants forever.”
                    56Mary stayed with her about three months, and then returned to her home.
                    </p>
                    23“Behold, the virgin will conceive and give birth to a Son, and they shall name Him Immanuel,” which translated means, “God with us.” 
                    <p>
                    14“Glory to God in the highest,And on earth peace among people with whom He is pleased.”
                              </p>
                              <p>
                    68“Blessed be the Lord God of Israel,For He has visited us and accomplished redemption for His people,
69And has raised up a horn of salvation for us In the house of His servant David—
70Just as He spoke by the mouth of His holy prophets from ancient times—
71 Salvation from our enemies, And from the hand of all who hate us;
72To show mercy to our fathers, And to remember His holy covenant,
73 The oath which He swore to our father Abraham,
74To grant us that we, being rescued from the hand of our enemies,Would serve Him without fear,
75In holiness and righteousness before Him all our days.
76And you, child, also will be called the prophet of the Most High; For you will go on before the Lord to prepare His ways;
77To give His people the knowledge of salvation By the forgiveness of their sins,
78Because of the tender mercy of our God, With which the Sunrise from on high will visit us,
79To shine on those who sit in darkness and the shadow of death,
                    To guide our feet into the way of peace.”
                    </p>
                    <p>
                              29“Now, Lord, You are letting Your bond-servant depart in peace, According to Your word;
                    30For my eyes have seen Your salvation,
                    31Which You have prepared in the presence of all the peoples:
                    32A light for revelation for the Gentiles, And the glory of Your people Israel.”
                    </p>
                    <p>
                    40Now the Child continued to grow and to become strong, increasing in wisdom; and the favor of God was upon Him.
                  
                    49And He said to them, “Why is it that you were looking for Me? Did you not know that I had to be in My Father’s house?”
                    
                    and His mother treasured all these things in her heart.
                    52And Jesus kept increasing in wisdom and stature, and in favor with God and people.
                    </p>
                    
                    <p>
                    The Witness John the Baptist
6A man came, one sent from God, and his name was John. 7He came as a witness, to testify about the Light, so that all might believe through him. 8He was not the Light, but he came to testify about the Light.
9  This was the true Light that, coming into the world, enlightens every person. 10He was in the world, and the world came into being through Him, and yet the world did not know Him. 11He came to His own, and His own people did not accept Him. 12But as many as received Him, to them He gave the right to become children of God, to those who believe in His name, 13who were born, not of blood, nor of the will of the flesh, nor of the will of a man, but of God.
The Word Made Flesh
14And the Word became flesh, and dwelt among us; and we saw His glory, glory as of the only Son from the Father, full of grace and truth. 15John *testified about Him and called out, saying, “This was He of whom I said, ‘He who is coming after me has proved to be my superior, because He existed before me.’ ” 16For of His fullness we have all received, and grace upon grace. 17For the Law was given through Moses; grace and truth were realized through Jesus Christ. 18No one has seen God at any time; God the only Son, who is in the arms of the Father, He has explained Him.
                              </p>
                               <p>
The Preaching of John the Baptist
1Now in those days John the Baptist *came, preaching in the wilderness of Judea, saying, 2“Repent, for the kingdom of heaven is at hand.” 3For this is the one referred to by Isaiah the prophet when he said,
“The voice of one calling out in the wilderness,
‘Prepare the way of the Lord,
Make His paths straight!’ ”
4Now John himself had a garment of camel’s hair and a leather belt around his waist; and his food was locusts and wild honey. 5At that time Jerusalem was going out to him, and all Judea and all the region around the Jordan; 6and they were being baptized by him in the Jordan River, as they confessed their sins.
7But when he saw many of the Pharisees and Sadducees coming for baptism, he said to them, “You offspring of vipers, who warned you to flee from the wrath to come? 8Therefore produce fruit consistent with repentance; 9and do not assume that you can say to yourselves, ‘We have Abraham as our father’; for I tell you that God is able, from these stones, to raise up children for Abraham. 10And the axe is already laid at the root of the trees; therefore, every tree that does not bear good fruit is being cut down and thrown into the fire.
11“As for me, I baptize you with water for repentance, but He who is coming after me is mightier than I, and I am not fit to remove His sandals; He will baptize you with the Holy Spirit and fire. 12His winnowing fork is in His hand, and He will thoroughly clear His threshing floor; and He will gather His wheat into the barn, but He will burn up the chaff with unquenchable fire.”
The Baptism of Jesus
                    13Then Jesus *arrived from Galilee at the Jordan, coming to John to be baptized by him. 14But John tried to prevent Him, saying, “I have the need to be baptized by You, and yet You are coming to me?” 15But Jesus, answering, said to him, “Allow it at this time; for in this way it is fitting for us to fulfill all righteousness.” Then he *allowed Him. 16After He was baptized, Jesus came up immediately from the water; and behold, the heavens were opened, and he saw the Spirit of God descending as a dove and settling on Him, 17and behold, a voice from the heavens said, “This is My beloved Son, with whom I am well pleased.”
                            </p>
                            <p>
                    2in the high priesthood of Annas and Caiaphas, the word of God came to John, the son of Zechariah, in the wilderness. 3And he came into all the region around the Jordan, preaching a baptism of repentance for the forgiveness of sins; 4as it is written in the book of the words of Isaiah the prophet:
“The voice of one calling out in the wilderness,
‘Prepare the way of the Lord,
Make His paths straight!
5Every ravine will be filled,
And every mountain and hill will be lowered;
The crooked will become straight,
And the rough roads smooth;
6And all flesh will see the salvation of God!’ ”
7So he was saying to the crowds who were going out to be baptized by him, “You offspring of vipers, who warned you to flee from the wrath to come? 8Therefore produce fruits that are consistent with repentance, and do not start saying to yourselves, ‘We have Abraham as our father,’ for I say to you that from these stones God is able to raise up children for Abraham. 9But indeed the axe is already being laid at the root of the trees; so every tree that does not bear good fruit is cut down and thrown into the fire.”
10And the crowds were questioning him, saying, “Then what are we to do?” 11And he would answer and say to them, “The one who has two tunics is to share with the one who has none; and the one who has food is to do likewise.” 12Now even tax collectors came to be baptized, and they said to him, “Teacher, what are we to do?” 13And he said to them, “Collect no more than what you have been ordered to.” 14And soldiers also were questioning him, saying, “What are we to do, we as well?” And he said to them, “Do not extort money from anyone, nor harass anyone, and be content with your wages.”
15Now while the people were in a state of expectation and they all were thinking carefully in their hearts about John, whether he himself perhaps was the Christ, 16John responded to them all, saying, “As for me, I baptize you with water; but He is coming who is mightier than I, and I am not fit to untie the straps of His sandals; He will baptize you with the Holy Spirit and fire. 17His winnowing fork is in His hand to thoroughly clear His threshing floor, and to gather the wheat into His barn; but He will burn up the chaff with unquenchable fire.”
18So with many other exhortations he preached the gospel to the people. 19But when Herod the tetrarch was reprimanded by him regarding Herodias, his brother’s wife, and regarding all the evil things which Herod had done, 20Herod also added this to them all: he locked John up in prison.
Jesus Is Baptized
21Now when all the people were baptized, Jesus also was baptized, and while He was praying, heaven was opened, 22and the Holy Spirit descended upon Him in bodily form like a dove, and a voice came from heaven: “You are My beloved Son, in You I am well pleased.”
                            </p>
                            <p>
The Testimony of John the Baptist
19This is the testimony of John, when the Jews sent priests and Levites to him from Jerusalem to ask him, “Who are you?” 20And he confessed and did not deny; and this is what he confessed: “I am not the Christ.” 21And so they asked him, “What then? Are you Elijah?” And he *said, “I am not.” “Are you the Prophet?” And he answered, “No.” 22Then they said to him, “Who are you? Tell us, so that we may give an answer to those who sent us. What do you say about yourself?” 23He said, “I am the voice of one calling out in the wilderness, ‘Make the way of the Lord straight,’ as Isaiah the prophet said.”
24And the messengers had been sent from the Pharisees. 25They asked him, and said to him, “Why then are you baptizing, if you are not the Christ, nor Elijah, nor the Prophet?” 26John answered them, saying, “I baptize in water, but among you stands One whom you do not know. 27It is He who comes after me, of whom I am not worthy even to untie the strap of His sandal.” 28These things took place in Bethany beyond the Jordan, where John was baptizing people.
29The next day he *saw Jesus coming to him, and *said, “Behold, the Lamb of God who takes away the sin of the world! 30This is He in behalf of whom I said, ‘After me is coming a Man who has proved to be my superior, because He existed before me.’ 31And I did not recognize Him, but so that He would be revealed to Israel, I came baptizing in water.” 32And John testified, saying, “I have seen the Spirit descending as a dove out of heaven, and He remained upon Him. 33And I did not recognize Him, but He who sent me to baptize in water said to me, ‘He upon whom you see the Spirit descending and remaining upon Him, this is the One who baptizes in the Holy Spirit.’ 34And I myself have seen, and have testified that this is the Son of God.”
</p>                 
                  </p>
                            <p className="author">
                              by{" "}
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <b>Jim Xiao </b>
                              </a>
                              2022
                            </p>
                          </Col>
                          <Col md="5">
                            <div className="card-image">
                              <img
                                alt="..."
                                className="img img-raised rounded"
                                src={
                                  require("assets/img/mise/card-blog6.jpg")
                                    .default
                                }
                              ></img>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md="12">
                <h2 className="title text-center">Minstries</h2>
                <br></br>
                <div className="blogs-1" id="blogs-1">
                  <Row>
                    <Col className="ml-auto mr-auto" md="10">
                      <Card className="card-plain card-blog">
                        <Row>
                          <Col md="5">
                            <div className="card-image">
                              <img
                                alt="..."
                                className="img img-raised rounded"
                                src={
                                  require("assets/img/mise/card-blog4.jpg")
                                    .default
                                }
                              ></img>
                            </div>
                          </Col>
                          <Col md="7">
                            <h6 className="category text-info">Emannuel</h6>
                            <CardTitle tag="h3">
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                               Jesus in Chinese Union
                              </a>
                            </CardTitle>
                            <p className="card-description">
                            耶稣受试探
（可1·12－13；路4·1－13）
1当时，耶稣被圣灵引到旷野，受魔鬼的试探。 2他禁食四十昼夜，后来就饿了。 3那试探人的进前来，对他说：“你若是　神的儿子，可以吩咐这些石头变成食物。” 4耶稣却回答说：“经上记着说：
‘人活着，不是单靠食物，
乃是靠　神口里所出的一切话。’”
5魔鬼就带他进了圣城，叫他站在殿顶（“顶”原文作“翅”）上， 6对他说：“你若是　神的儿子，可以跳下去，因为经上记着说：
‘主要为你吩咐他的使者
用手托着你，
免得你的脚碰在石头上。’”
7耶稣对他说：“经上又记着说：‘不可试探主你的　神。’” 8魔鬼又带他上了一座最高的山，将世上的万国与万国的荣华都指给他看， 9对他说：“你若俯伏拜我，我就把这一切都赐给你。” 10耶稣说：“撒但（“撒但”就是“抵挡”的意思，乃魔鬼的别名），退去吧！因为经上记着说：
‘当拜主你的　神，
单要侍奉他。’”
11于是，魔鬼离了耶稣，有天使来伺候他。
                  
                    
                            </p> 
                            <p className="author">
                              by{" "}
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                              <b>Matthew </b>
                              </a>
                              , 1-24-2022 
                            </p>
                            <p className="card-description">
                            耶稣受试探
（太4·1－11；路4·1－13）
                            12圣灵就把耶稣催到旷野里去。 13他在旷野四十天，受撒但的试探，并与野兽同在一处，且有天使来伺候他。
                            </p>
                            <p className="author">
                              by{" "}
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <b>Mark </b>
                              </a>
                              , 1-24-2022 
                            </p>
                            <p className="card-description">
                            耶稣受试探
（太4·1－11；可1·12－13）
1耶稣被圣灵充满，从约旦河回来，圣灵将他引到旷野， 2四十天受魔鬼的试探。那些日子没有吃什么；日子满了，他就饿了。 3魔鬼对他说：“你若是　神的儿子，可以吩咐这块石头变成食物。” 4耶稣回答说：“经上记着说：‘人活着不是单靠食物，乃是靠　神口里所出的一切话。’” 5魔鬼又领他上了高山，霎时间把天下的万国都指给他看， 6对他说：“这一切权柄、荣华，我都要给你，因为这原是交付我的，我愿意给谁就给谁。 7你若在我面前下拜，这都要归你。” 8耶稣说：“经上记着说：
‘当拜主你的　神，
单要侍奉他。’”
9魔鬼又领他到耶路撒冷去，叫他站在殿顶（“顶”原文作“翅”）上，对他说：“你若是　神的儿子，可以从这里跳下去； 10因为经上记着说：
‘主要为你吩咐他的使者保护你；
11他们要用手托着你，
免得你的脚碰在石头上。’”
12耶稣对他说：“经上说：‘不可试探主你的　神。’” 13魔鬼用完了各样的试探，就暂时离开耶稣。                            </p>
                            <p className="author">
                              by{" "}
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <b>Luke </b>
                              </a>
                              , 1-24-2022 
                            </p>

                            <p className="card-description">
                            耶稣受试探

                            </p>
                            <p className="author">
                              by{" "}
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <b>John </b>
                              </a>
                              , 1-24-2022 
                            </p>
                            
                            
                          </Col>
                        </Row>
                      </Card>
                      <Card className="card-plain card-blog">
                        <Row>
                          <Col md="7">
                            <h6 className="category text-danger">
                              <i className="now-ui-icons now-ui-icons media-2_sound-wave"></i>{" "}
                              Gospels -- Ministries!
                            </h6>
                            <CardTitle tag="h3">
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                 Jesus in NASB 
                              </a>
                            </CardTitle>
                            <p className="card-description">
                            The Temptation of Jesus
1Then Jesus was led up by the Spirit into the wilderness to be tempted by the devil. 2And after He had fasted for forty days and forty nights, He then became hungry. 3And the tempter came and said to Him, “If You are the Son of God, command that these stones become bread.” 4But He answered and said, “It is written: ‘Man shall not live on bread alone, but on every word that comes out of the mouth of God.’ ”
5Then the devil *took Him along into the holy city and had Him stand on the pinnacle of the temple, 6and he *said to Him, “If You are the Son of God, throw Yourself down; for it is written:
‘He will give His angels orders concerning You’;
and
‘On their hands they will lift You up,
So that You do not strike Your foot against a stone.’ ”
7Jesus said to him, “On the other hand, it is written: ‘You shall not put the Lord your God to the test.’ ”
8Again, the devil *took Him along to a very high mountain and *showed Him all the kingdoms of the world and their glory; 9and he said to Him, “All these things I will give You, if You fall down and worship me.” 10Then Jesus *said to him, “Go away, Satan! For it is written: ‘You shall worship the Lord your God, and serve Him only.’ ” 11Then the devil *left Him; and behold, angels came and began to serve Him.
                            </p>
                            <p className="author">
                              by{" "}
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <b>Matthew </b>
                              </a>
                              2022
                            </p>

                            <p className="card-description">
                            12And immediately the Spirit *brought Him out into the wilderness. 13And He was in the wilderness for forty days, being tempted by Satan; and He was with the wild animals, and the angels were serving Him.
                            </p>
                            <p className="author">
                              by{" "}
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <b>Mark </b>
                              </a>
                              2022
                            </p>
                            <p className="card-description">
                            The Temptation of Jesus
1Now Jesus, full of the Holy Spirit, returned from the Jordan and was led around by the Spirit in the wilderness 2for forty days, being tempted by the devil. And He ate nothing during those days, and when they had ended, He was hungry. 3And the devil said to Him, “If You are the Son of God, tell this stone to become bread.” 4And Jesus answered him, “It is written: ‘Man shall not live on bread alone.’ ”
5And he led Him up and showed Him all the kingdoms of the world in a moment of time. 6And the devil said to Him, “I will give You all this domain and its glory, for it has been handed over to me, and I give it to whomever I want. 7Therefore if You worship before me, it shall all be Yours.” 8Jesus replied to him, “It is written: ‘You shall worship the Lord your God and serve Him only.’ ”
9And he brought Him into Jerusalem and had Him stand on the pinnacle of the temple, and said to Him, “If You are the Son of God, throw Yourself down from here; 10for it is written:
‘He will give His angels orders concerning You, to protect You,’
11and,
‘On their hands they will lift You up,
So that You do not strike Your foot against a stone.’ ”
12And Jesus answered and said to him, “It has been stated, ‘You shall not put the Lord your God to the test.’ ”
13And so when the devil had finished every temptation, he left Him until an opportune time.
                            </p>
                            <p className="author">
                              by{" "}
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <b>Luke </b>
                              </a>
                              2022
                            </p>
                            <p className="card-description">
                            </p>
                            <p className="author">
                              by{" "}
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <b>John </b>
                              </a>
                              2022
                            </p>




                          </Col>

                          <Col md="5">
                            <div className="card-image">
                              <img
                                alt="..."
                                className="img img-raised rounded"
                                src={
                                  require("assets/img/mise/card-blog6.jpg")
                                    .default
                                }
                              ></img>
                            </div>
                          </Col>
                        </Row>

                      </Card>


                      
                    </Col>
                  </Row>
                </div>
              </Col>
            </Container>
          </div>
        </div>

        <Row>
                <Col md="12">
                  <h4>
                    <small>Gospels</small>
                  </h4>
                </Col>
                <Col md="12">
                  <Card className="card-plain">
                    <CardBody>
                      <Table className="table-shopping" responsive>
                        <thead>
                          <tr>
                      <th className="text-center"></th>
                      <th>Events</th>
                            <th>Matthew</th>
                            <th>Mark</th>
                      <th>Luke</th>
                      <th>John</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="img-container">
                                <img
                                  alt="..."
                                  src={
                                    require("assets/img/saint-laurent.jpg")
                                      .default
                                  }
                                ></img>
                              </div>
                            </td>
                            <td className="td-name">
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Birth
                              </a>
                              <br></br>
                              <small>Emannuel</small>
                            </td>
                      <td>Black
                        
                            </td>
                            <td>Black
                        
                      </td>
                      <td>Black
                        
                      </td>
                      <td>Black
                        
                        </td>

                          </tr>


                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
        <FooterBlack />
      </div>
    </>
  );
}

export default BlogPost;
